import React from "react";
import NotFoundImage from "../assets/images/404 Page Not Found _Isometric.png";
import Grid2 from "@mui/material/Unstable_Grid2";
import Button from '@mui/material/Button';
import ButtonAppBar from "../components/menu";
import Container from "@mui/material/Container";

function NotFound() {

  return (
    <>
    <Container>
    <ButtonAppBar />
        <Grid2 container spacing={2}>
          <Grid2 xs={12} display="flex" justifyContent="center" alignItems="center">
            <img src={NotFoundImage} alt="Not Found" />
          </Grid2>
          <Grid2 xs={12} display="flex" justifyContent="center" alignItems="center">
          <Button variant="contained" size="large" href="/">
            HOME
            </Button>
          </Grid2>
        </Grid2>
    </Container>
    </>
  );
}

export default NotFound;
