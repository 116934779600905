import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import axios from "axios";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function UrlTable(props) {
  const userId = props.data.userId;
  //use axios to get data from /api/UrlOperations
  const [rows, setRows] = useState([]);


  useEffect(() => {
    axios
      .get("/api/UrlOperation?userId=" + userId)
      .then((response) => {
        // Handle success
        if (response.status === 200) {
          setRows(response.data);
        } else {
          // Handle error
          console.log("Retrieve data Error");
        }
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  }, [userId]);

  const handleRefresh = () => {
    axios
    .get("/api/UrlOperation?userId=" + userId)
    .then((response) => {
      // Handle success
      if (response.status === 200) {
        setRows(response.data);
      } else {
        // Handle error
        console.log("Retrieve data Error");
      }
    })
    .catch((error) => {
      // Handle error
      console.log("Error");
    });
  };

  //function to delete item
  const deleteItem = (id) => {
    axios
      .delete("/api/UrlOperation?id=" + id + "&userId=" + userId)
      .then((response) => {
        // Handle success
        if (response.status === 200) {
          setRows(rows.filter((item) => item.id !== id));
        } else {
          // Handle error
          console.log("Delete Item Error");
        }
      });
  };

  return (
    <>
      <Grid2 container>
        <Grid2 xs={6} display="flex" justifyContent="start" alignItems="center">
          <Typography variant="h6" component="h1" gutterBottom sx={{ pt: 2 }}>
            Your URL List
          </Typography>
        </Grid2>
        <Grid2 xs={6} display="flex" justifyContent="end" alignItems="center">
          <IconButton aria-label="refresh" onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Grid2>
      </Grid2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="url table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Original URL</b>
              </TableCell>
              <TableCell align="center">
                <b>Alias</b>
              </TableCell>
              <TableCell align="center">
                <b>View Count</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    width: "40%",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  {row.longurl}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "30%", whiteSpace: "normal" }}
                >
                  {row.alias}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "15%", whiteSpace: "normal" }}
                >
                  {row.view}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "15%", whiteSpace: "normal" }}
                >
                  {" "}
                  <IconButton
                    aria-label="open new tab"
                    onClick={() => {
                      window.location.href = row.longurl;
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      deleteItem(row.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
