import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { getUserData } from "../services/swa-user";
import React, { useState, useEffect } from "react";

export default function ButtonAppBar() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      // get data from getUserData, and set it to userData
      const data = await getUserData();
      setUserData(data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    //console.log(userData);
  }, [userData]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Zap URL Shortener
            </Typography>
            {userData ? (
              <Button color="inherit" href="/.auth/logout">
                Logout
              </Button>
            ) : (
              <Button color="inherit" href="/.auth/login/aad">
                Login
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
