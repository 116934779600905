import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import ButtonAppBar from "../components/menu";
import LoginPage from "../components/unauthenticated";
import Admin from "../components/authenticated";
import { getUserData } from "../services/swa-user";

function Home() {

    const [userData, setUserData] = useState(null);

    useEffect(() => {
      async function fetchData(){
        // get data from getUserData, and set it to userData
        const data = await getUserData();
        setUserData(data);
      }
      fetchData();
      }, []);
      
      useEffect(() => {
        // log the updated userData
        //console.log(userData);
      }, [userData]);


  return (
    <>
      <Container>
        <ButtonAppBar />
        {userData ? <Admin data={userData} /> : <LoginPage />}
      </Container>
    </>
  );
}

export default Home;
