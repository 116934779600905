import React, { useState } from "react";
import axios from "axios";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";

function App() {
  const [notFound, setNotFound] = useState(false);
  // get http request referer header, then print out the url
  console.log("Referer: " + document.referrer);
  // Get current path, and remove the first slash
  const currentPath = window.location.pathname.substring(1);
  // If current path is not empty, use currentPath and call API to /api/OriginUrl
  if (currentPath !== "") {
    axios
      .get("/api/OriginUrl?alias=" + currentPath)
      .then((response) => {
        // Handle success
        if (response.status === 200) {
          window.location.href = response.data;
        } else {
          // Handle error
          setNotFound(true);
        }
      })
      .catch((error) => {
        // Handle error
        setNotFound(true);
      });
  }

  if (notFound) {
    return <NotFound />;
  } else if (currentPath === "") {
    return <Home />;
  } else {
    return null;
  }
}

export default App;
