import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";
import FileUploader from "react-material-file-upload";
import Button from "@mui/material/Button";
import PublishIcon from "@mui/icons-material/Publish";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import QRCode from "react-qr-code";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import ClearIcon from "@mui/icons-material/Clear";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CreateByFile(props) {
  const [files, setFiles] = useState([]);
  const [days, setDays] = useState(7);
  const [url, setUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [errorBanner, setErrorBanner] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentLocation =
    window.location.protocol + "//" + window.location.hostname;

  //Handle the slider change and setDays
  const handleSliderChange = (event, newValue) => {
    setDays(newValue);
  };

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 14,
      label: "14",
    },
  ];

  const handleFileUpload = async () => {
    const file = files[0];
    // check if at least one file is uploaded
    if (file) {
      // create a formdata object
      const formData = new FormData();
      // append the file to the formdata
      files.forEach((file) => {
        formData.append("file", file);
      });
      setLoading(true);

      // send a post request to the azure function api with the formdata
      try {
        const response = await axios.post(
          "/api/FileOperation?duration=" + days,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // get the blob SAS token URL from the response
        const blobUrl = response.data;
        const urlBody = {
          longurl: blobUrl,
          alias: document.getElementById("alias").value,
          userId: props.data.userId,
          view: 0,
        };
        axios
          .post("/api/UrlOperation", urlBody)
          .then((response) => {
            // Handle success
            if (response.status === 200) {
              //console.log("Create Success");
              setUrl(response.data.alias);
              setLoading(false);
            } else {
              // Handle error
              console.log("Create Error");
            }
          })
          .catch((error) => {
            // Handle error
            console.log(error);
            setErrorBanner(true);
            setLoading(false);
            setTimeout(() => setErrorBanner(false), 3500);
          });
      } catch (error) {
        // handle error
        console.error(error);
      }
    } else {
      // alert user to select a file
      alert("Please select a file to upload");
    }
  };

  const handleClearClick = () => {
    setFiles([]);
    setUrl(null);
  };

  const handleCopyClick = (genURL) => {
    // Copy the current URL to the clipboard
    navigator.clipboard.writeText(genURL);
    // Show the snackbar for 2 seconds
    setOpen(true);
    setTimeout(() => setOpen(false), 2000);
  };

  return (
    <>
      <Grid2 container>
        <Grid2
          xs={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid2
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FileUploader value={files} onChange={setFiles} />
          </Grid2>
        </Grid2>

        <Grid2
          xs={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box sx={{ width: "100%", px: 5 }}>
            <Stack spacing={3}>
              <Grid2 container>
                <Grid2
                  xs={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="button" gutterBottom>
                    Days to expire
                  </Typography>
                </Grid2>

                <Grid2
                  xs={8}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Slider
                    aria-label="Days to expire"
                    defaultValue={7}
                    value={days}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={marks}
                    min={1}
                    max={14}
                    onChange={handleSliderChange}
                  />
                </Grid2>
              </Grid2>

              <TextField
                id="alias"
                label="Alias (optional)"
                variant="outlined"
                fullWidth
                size="small"
              />
              <Box sx={{ m: 1, position: "relative" }}>
                <Grid2 container>
                  <Grid2
                    xs={6}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      endIcon={<PublishIcon />}
                      onClick={handleFileUpload}
                    >
                      Get URL
                    </Button>
                  </Grid2>
                  <Grid2
                    xs={6}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      endIcon={<ClearIcon />}
                      onClick={handleClearClick}
                      color="error"
                    >
                      Clear
                    </Button>
                  </Grid2>
                </Grid2>

                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: grey[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Box>
        </Grid2>

        {url ? (
          <>
            <Grid2
              xs={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ pt: 2 }}
            >
              <QRCode value={currentLocation + "/" + url} />
            </Grid2>
            <Grid2
              xs={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="outlined"
                endIcon={<FileCopyIcon />}
                onClick={() => handleCopyClick(currentLocation + "/" + url)}
              >
                Copy Short URL to Clipboard
              </Button>
            </Grid2>
          </>
        ) : (
          <></>
        )}
      </Grid2>
      <Snackbar open={open}>
        <Alert severity="info" sx={{ width: "100%" }}>
          Copied!
        </Alert>
      </Snackbar>

      <Snackbar open={errorBanner}>
        <Alert severity="warning" sx={{ width: "100%" }}>
          Error! Either the alias is duplicated or our backend has issues.
          Please try again later.
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateByFile;
