import React, { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import QRCode from "react-qr-code";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//const regex = /^(https?:\/\/)?(www\.)?([a-z0-9-]+\.)+[a-z]{2,6}(\/[\w\-\.~:@!$&'()*+,;=]*)*\/?$/i;
const regex = /^(https?:\/\/)?(www\.)?([a-z0-9-]+\.)+[a-z]{2,6}(\/[\w\-.~:@!$&'()*+,;=]*)*\/?$/i;

function CreateByUrl(props) {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [errorBanner, setErrorBanner] = useState(false);

  const currentLocation = window.location.protocol + "//" + window.location.hostname;
  //create a function to perform http request, by using axios, and use TextField id as body
  const createUrl = () => {
    const longUrl = document.getElementById("longUrl").value;
    const alias = document.getElementById("alias").value;
    const userId = props.data.userId;
    const isValid = regex.test(longUrl);

    // if longUrl is not null or empty
    if (isValid) {
    const body = {
      longurl: longUrl,
      alias: alias,
      userId: userId,
      view: 0,
    };
    axios
      .post("/api/UrlOperation", body)
      .then((response) => {
        // Handle success
        if (response.status === 200) {
          //console.log("Create Success");
          setData(response.data.alias);
        } else {
          // Handle error
          console.log("Create Error");
        }
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        setErrorBanner(true);
        setTimeout(() => setErrorBanner(false), 3500);
      });
    } else {
      alert("Please input a valid url!");
    }
  };

  const handleCopyClick = (genURL) => {
    // Copy the current URL to the clipboard
    navigator.clipboard.writeText(genURL);
    // Show the snackbar for 2 seconds
    setOpen(true);
    setTimeout(() => setOpen(false), 2000);
  };

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2
          xs={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            id="longUrl"
            label="Long URL"
            variant="outlined"
            required
            fullWidth
          />
        </Grid2>
        <Grid2
          xs={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TextField id="alias" label="Alias" variant="outlined" fullWidth />
        </Grid2>
        <Grid2
          xs={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={() => {
              createUrl();
            }}
          >
            Create
          </Button>
        </Grid2>
        {data ? 
        <>
                  <Grid2
          xs={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        <QRCode value={currentLocation + '/' + data} />
        </Grid2>
        <Grid2
          xs={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        <Button variant="outlined" endIcon={<FileCopyIcon />} onClick={() => handleCopyClick(currentLocation + '/' + data)}>
          Copy Short URL to Clipboard
          </Button>
        </Grid2>
        </>

         : <></>}
      </Grid2>
      <Snackbar open={open}>
        <Alert severity="info" sx={{ width: '100%' }}>
          Copied!
        </Alert>
      </Snackbar>

      <Snackbar open={errorBanner}>
        <Alert severity="warning" sx={{ width: '100%' }}>
          Error! Either the alias is duplicated or our backend has issues. Please try again later.
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateByUrl;
