import React from "react";
import ShortUrl from "./shorturl";
import UrlTable from "./urllist";

function Admin(props) {

  return (
    <>
    <ShortUrl data={props.data}/>
    <UrlTable data={props.data}/>
    </>
  );
}

export default Admin;