import React from "react";
import SEO from "../assets/images/SEO_Isometric.png";
import Grid2 from "@mui/material/Unstable_Grid2";
import Button from '@mui/material/Button';

function LoginPage() {

  return (
    <>
        <Grid2 container spacing={2}>
          <Grid2 xs={12} display="flex" justifyContent="center" alignItems="center">
            <img src={SEO} alt="SEO" />
          </Grid2>
          <Grid2 xs={12} display="flex" justifyContent="center" alignItems="center">
          <Button variant="contained" size="large" href="/.auth/login/aad">
            LOGIN
            </Button>
          </Grid2>
        </Grid2>
    </>
  );
}

export default LoginPage;
